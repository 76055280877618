import React from 'react'
import MemphisPattern from '@components/MemphisPattern'
import HeroBoxed from '@widgets/HeroBoxed'
import { Box, Heading, Button } from 'theme-ui'
import { Link } from 'gatsby'
import { FaWhatsapp } from 'react-icons/fa'
import { IoIosCall } from 'react-icons/io'
import { RiHandCoinFill} from "react-icons/ri";

const Barra_bassa = () => (
  <>

     <div className="Barra_pc"> 
     <div style={{border:"0px solid #ff6600", background:"linear-gradient(45deg,#fc7a51,#fc7a51)", borderRadius:"25px", padding: "8px", fontSize:"20px"}} >
     <a href="#ContactForm">Chiedi Rimborso
    </a>
     </div>
     <div>
     <a style={{color: "white", fontSize:"20px"}}href="mailto: info@sinistrostradalerisarcito.it">
     info@eurorimborso.com
     </a>
     </div>
     <div>
     <a style={{color: "white", fontSize:"20px"}} href="tel:0692915637">
     0692915637
     </a>
     </div>

    </div>
    <div className="Barra_mobile" style={{lineHeight:"0.8"}}> 
     <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems: "center"}} >
      <div style={{fontSize:"30px", alignItems: "center"} }>
      <RiHandCoinFill/>
      </div>
      <div>
      <a href="#ContactForm">Rimborso
      </a>
     </div>
     </div>
     <div>
     <a style={{color: "white", textDecoration: "none", display:"flex", flexDirection:"column", justifyContent:"center", alignItems: "center" }} href="https://wa.me/3273536006">
     <div style={{fontSize:"30px", alignItems: "center"}}>
      <FaWhatsapp/>
      </div>
      <div>
     WhatsApp
     </div>
     </a>
     </div>
     <div>
     <a style={{color: "white", textDecoration: "none", display:"flex", flexDirection:"column", justifyContent:"center", alignItems: "center" }} href="tel:0692915637">
     <div style={{fontSize:"30px", alignItems: "center"}}>
      <IoIosCall/>
      </div>
      <div style={{color: "#01ff01"}}>
      0692915637
     </div>
     </a>
     </div>

    </div>

  
  </>
)

export default Barra_bassa
