import React from 'react'
import MemphisPattern from '@components/MemphisPattern'
import HeroBoxed from '@widgets/HeroBoxed'
import { Box, Heading, Button } from 'theme-ui'
import { Link } from 'gatsby'



const Sezionepagetesto = () => (
  <>
  <HeroBoxed.Wrapper>


     <div className="boxsezione4"> 
     <div style={{width:"100%", textAlign:"center"}}>
     <Heading variant='h2'>
     
     </Heading>
     
      </div>

<h2>Che cos’è il risarcimento per incidente stradale</h2>
<p>
Per “risarcimento per incidente stradale” si intende la somma riconosciuta da una compagnia assicurativa ad un soggetto coinvolto in un incidente stradale. La liquidazione del danno naturalmente non è immediata ma avviene solo dopo aver accertato gli eventi avvenuti nel sinistro. Attraverso un analisi di quest’ultimi si determinerà il grado di responsabilità e si procederà con il risarcimento. 

<h2>Incidente stradale in auto</h2>
Siamo consapevoli di quanto un incidente in auto possa essere un evento che può turbarti soprattutto quando a seguito di questi hai riportato dei danni fisici e psicologici. Gli incidenti capitano per i più svariati motivi e non bisogna farsene una colpa. In queste situazioni c’è la necessità di prendere di petto la situazione ed affrontarla nel modo più corretto possibile.

<h2>Cosa fare immediatamente dopo un incidente stradale?</h2>
<ul>
<li>Prima cosa da fare, mantenere la calma in questo momento sarai frastornato e non ti renderai conto di quanto è successo e di quali siano i danni che hai subito.</li><br></br>
<li>Dopo esserti accertato di non aver subito lesioni importanti cerca di capire le condizioni degli altri soggetti coinvolti nell’incidente (eventuali passeggeri, i soggetti presenti negli altri veicoli coinvolti ecc. ecc.. </li>
</ul>
 
A questo punto devi seguire due strade diverse in base agli elementi oggettivi che riscontri.
Se non ci sono feriti nell’incidente:
Spostate prontamente gli autoveicoli dalla carreggiata principale e compilate il CAD (o cid). Se vuoi scaricare il modulo CAD spiegato nei dettagli puoi farlo da qui. Basterà compilare tutte le sezioni procedendo anche alla raffigurazione grafica dell’incidente.

Se invece purtroppo ci sono feriti nell’incidente:

Non spostare assolutamente gli autoveicoli dalla carreggiata avendo cura però di segnalare l’area adeguatamente con il triangolo. 
Chiamare immediatamente i soccorsi e le forze dell’ordine. Soprattutto in questa fase cerca di mantenere la calma e di rispondere a tutte le domande che fa l’operatore. E’ di cruciale importanza fornire tutti i dettagli nel modo più preciso possibile. Se non riesci a fornire informazioni sulla tua posizione cerca di individuare un punto di riferimento come può essere un monumento, un palazzo, un negozio.
Attendi i soccorsi e nel frattempo “monitora” le condizioni dei soggetti feriti.   
Cosa fare dopo qualche ora dall’incidente stradale?
Se non hai riportato lesioni contatta la tua compagnia assicurativa (recandoti personalmente, tramite raccomandata, pec). Se invece hai riportato lesioni lievi recati subito in pronto soccorso per sottoporti ad un controllo medico.

In entrambi i casi puoi sempre contattarci e ti guideremo in tutti i passaggi della tua pratica.<br></br><br></br>

Modulo online qui <br></br>

email: info@sinistrostradalerisarcito.it<br></br>

Tel: 0692915637<br></br>


</p>



    </div>
  
  </HeroBoxed.Wrapper>
  
  </>
)


const testimonialQuery = graphql`
  query TestimonialQuery {
    file(absolutePath: { regex: "/testimonial.(jpeg|jpg|gif|png)/" }) {
      publicURL
    }
  }
  `
export default Sezionepagetesto
