import React from 'react'
import PropTypes from 'prop-types'
import { Box, Label, Input, Textarea, Button, Message, Spinner, Radio, Select, Checkbox, Switch} from 'theme-ui'


/**
 * How to enable form integration:
 *
 * 1) Shadow this component
 * 2) Remove the demo attribute from the form tag.
 * 3) Add your action end-point to the form tag.
 * 4) If required by your form API provider, add the hidden input(s).
 *
 * Some recommended serverless form providers:
 * Getform (https://www.gatsbyjs.com/docs/building-a-contact-form/#getform)
 * Formspree (https://www.gatsbyjs.com/docs/building-a-contact-form/#formspree)
 * Netlify Form (https://www.gatsbyjs.com/docs/building-a-contact-form/#netlify)
 * ho tolto action='YOUR_ACTION_END_POINT'
 */


const ContactForm = ({ handleSubmit, submitting, success }) => (
  <form
    onSubmit={handleSubmit}
    method='POST'
  
    netlify-honeypot="bot-field" 
    data-netlify="true"
    name="contact">
    
    <input type="hidden" name="bot-field" />
    <input type="hidden" name="form-name" value="contact" />
  
    {success === true && (
      <Message variant='success'>
        Grazie per il messaggio. Presto un nostro operatore ti contatterà. 
      </Message>
    )}
    {success === false && (
      <Message variant='error'>
        Qualcosa è andato storto si prega di riprovare!
      </Message>
    )}
        <div style={{textAlign: "", color:"#667eea"}}>
    
    <b>CHIEDI IL RIMBORSO AEREO</b>  
    </div>
    <div style={{textAlign: "", color:""}}>
    Scopri se hai diritto ad un risarcimento!    
    </div>
    <br></br>
    <Box variant='forms.row'>
    

      <Box variant='forms.column'>
        <Label htmlFor='contact-form-name'>Nome e Cognome</Label>
        <Input type='text' id='contact-form-name' name='name' required />
      </Box>
    </Box>
    <Box variant='forms.row'>
      <Box variant='forms.column'>
        <Label htmlFor='contact-form-email'>Email</Label>
        <Input
          type='email'
          placeholder='email@esempio.it'
          id='contact-form-email'
          name='email'
          required
        />
      </Box>
      <Box variant='forms.column'>
        <Label htmlFor='contact-form-phone'>Telefono</Label>
        <Input
          type='tel'
          placeholder=''
          id='contact-form-phone'
          name='phone'
        />

      </Box>
      </Box>
      <Box>
      <Box variant='forms.row'>
      <Box variant='forms.column'>
        <Label htmlFor='contact-form-email'>Numero di Volo</Label>
        <Input
          type='Text'
          placeholder='AD ES. 10245'
          id='contact-form-email'
          name='numero-volo'
          required
        />
      </Box>
      <Box variant='forms.column'>
        <Label htmlFor='contact-form-phone'>Data Disagio</Label>
        <Input
          type='Date'
          placeholder='gg/mm/aaaa'
          id='Date'
          name='Data'
        />
        
      </Box>
      </Box>


      
      
    </Box>
    
    

<Box>

   <Label htmlFor='contact-form-phone'>Seleziona il disservizio</Label>
        
<Label>
  <Radio name="disservizio" value="Volo in Ritardo" /> Volo in Ritardo (min. 3 ore)
</Label>
<Label>
  <Radio name="disservizio" value="Volo Cancellato" /> Volo Cancellato
</Label>
<Label>
  <Radio name="disservizio" value="Overbooking" /> Overbooking
</Label>
<Label>
  <Radio name="disservizio" value="Bagaglio smarrito o danneggiato" /> Bagaglio smarrito o danneggiato
</Label>
<Label>
  <Radio name="disservizio" value="Altro" /> Altro
</Label>
<br></br>

{/*

Se Vogliamo mettere la tendina... attivare questo commento 
<box>


<Select name="sound" id="sound" mb={3}>
  <option>Seleziona il disservizio</option>
    <option>Volo in Ritardo (min. 3 ore)</option>
    <option>Volo Cancellato</option>
    <option>Overbooking</option>
    <option>Bagaglio smarrito o danneggiato</option>
    <option>Altro</option>
  </Select>


</box>
*/}
    </Box>
    <Box variant='forms.row'>
      <Label htmlFor='contact-form-message'>Cos’è successo?</Label>
      <Label htmlFor='contact-form-message'><h5>Breve descrizione degli eventi. Comunicaci tutte le informazioni che ritirni utili alla verifica del diritto al risarcimento</h5></Label>

      <Textarea name='message' id='contact-form-message' />
    </Box>
<box>
<Label>
  <Checkbox  efaultChecked={true} name='Privacy' /> <b>Privacy</b> -  accettazione privacy ai sensi D.LGS 196/2003
  
</Label>

<box>

</box>

</box>


    <Button
      variant={success || submitting ? 'disabled' : 'primary'}
      disabled={success || submitting}
      type='submit'
      required
    >
      INVIA RICHIESTA {submitting && <Spinner size='20' />}
    </Button>

<Box p={4} bg="primary">
 
</Box>
  </form>
)

export default ContactForm

ContactForm.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  success: PropTypes.bool
}
